import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import Helmet from 'react-helmet'
import Header from "./header/header";
import Footer from "./footer/footer";
import { createGlobalStyle } from "styled-components"

const Layout = ({ path, children, shadowNav, noFooter, noHeader, mainBg }) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    const GlobalStyle = createGlobalStyle`
        html{
            background: #E1E5EA;
        }
        body{
            overflow-x: hidden;
        }
        @media (max-width: 992px){
            h1, h2 {
                font-size: 3rem !important;
            }
        }

        @media (max-width: 576px){
            h1, h2 {
                font-size: 2rem !important;
            }
        }
        @media (max-width: 478px){
            h1,h2 {
                font-size: 1.2rem !important;
            }
            h3 {
                font-size: 1rem !important;
            }
        }
	    main{
            background:${mainBg ? mainBg : "#fff"};
	    }
        .btn.btn-link.dx-g-bs4-table-edit-command-cell{
            padding: 5px 10px !important;
            color: white !important;
            text-decoration: none !important;
            border-radius: 3px !important;
            margin: 5px !important; 
        }
        .dx-g-bs4-toolbar {
            button{
                &:hover{
                    background: #1D2146 !important;
                    color: white !important;
                }
                padding: 5px 10px !important;
                background: transparent !important;
                border:solid 1px #1D2146 !important;
                border-radius:3px !important;
                margin-right: 10px !important;
                .oi.oi-data-transfer-download{
                   &::after{
                    content: "🡣 Excel";
                   }
                }
            }
        }
        `

    const url = typeof window !== 'undefined' ? window.location.pathname : '';

    return (
        <>
            <GlobalStyle />
            <Helmet>
                <script src={withPrefix('fontawesome.js')} type="text/javascript" />
            </Helmet>
            {!noHeader && (
                <Header path={path} siteTitle={data.site.siteMetadata?.title || `Title`} shadowNav={shadowNav} />
            )}
            <main>
                {children}
            </main>
            {!noFooter && (
                <Footer />
            )}
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
