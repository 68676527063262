import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Link } from 'gatsby';

const NavbarContainer = styled(Navbar)`
    box-shadow: ${props => props.shadowNav ? props.isFixed ? "none !important" : "0px 23px 35px 17px #fff" : "none !important"};
    transition: all 0.2s !important;
    .nav-link{
        transition: all 0.2s !important;
        font-size: ${props => props.fixed ? "1rem !important;" : "1.2rem !important;"} 
    }
    i{
        transition: all 0.2s !important;
        font-size: ${props => props.fixed ? "1.2rem !important;" : "1.5rem !important;"} 
    }
`
const ContainerLogoNav = styled(Link)`
    img{
        transition: all 0.2s !important;
        width: ${props => props.fixed ? "150px" : "200px"} !important;
    }
`

const Header = ({ shadowNav }) => {

    // efecto scroll------------------------------------------
    const [isFixed, setIsFixed] = useState(false);
    let lastScroll = useRef(0);
    useEffect(() => {
        function onScroll() {
            const currentScroll = window.pageYOffset;

            if (window.scrollY >= 5) {
                setIsFixed(true);
            }
            else {
                setIsFixed(false);
            }
            lastScroll.current = currentScroll;
        }
        window.addEventListener("scroll", onScroll);
    }, []);
    // efecto scroll------------------------------------------


    return (
        <NavbarContainer fixed={isFixed} shadowNav={shadowNav} collapseOnSelect expand="lg" bg="white" variant="light" className="fixed-top">
            <Container>
                <ContainerLogoNav className="navbar-brand" to="/" fixed={isFixed}>
                    <StaticImage src="../../images/logo.png" alt="logo-nav" />
                </ContainerLogoNav>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="m-auto">
                        <Link className="nav-link" to="/">Inicio</Link>
                        <NavDropdown title="Barrios" id="basic-nav-dropdown">
                            <Link className="dropdown-item" to="/barrio/el-canal">El Canal</Link>
                            <Link className="dropdown-item" to="/barrio/el-portal">El Portal</Link>
                            <Link className="dropdown-item" to="/barrio/bosques">Bosques</Link>
                            <Link className="dropdown-item" to="/barrio/los-lagos">Los Lagos</Link>
                            <Link className="dropdown-item" to="/barrio/saltos">Saltos</Link>
                        </NavDropdown>
                        <Link className="nav-link" to="/el-faro">El Faro</Link>
                        <Link className="nav-link" to="/contacto">Contacto</Link>
                    </Nav>
                    <Nav>
                        <Link to="/login" className="nav-link">Ingresar <i className="fal fa-user"></i></Link>
                        {/* <Link eventKey={2} href="#memes" className="nav-link">
                            <i className="fal fa-search"></i>
                        </Link> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </NavbarContainer>
    );
}

export default Header;