import React from 'react';
import { Row, Col, Container } from 'react-bootstrap'
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";
import BgFooter from '../../images/timonBg.png'
import { Link } from 'gatsby';

const ContainerGeneral = styled.footer`
    position: relative;
    background-color: #F0F3F4;
    padding:3% 0% 0%;
    background-image: url(${BgFooter}); /* The image used */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
`
const P = styled.p`
    color:#231F20;
    padding-top: 2%;
`
const H2 = styled.h2`
    text-align: center;
    color:#000;
    font-size: 3rem;
`
const H3 = styled.h3`
    text-align: center;
    color:#C1292F;
    font-size: 1.2rem;
`


const Footer = () => {
    return (
        <ContainerGeneral>
            <Container className="pb-5 pt-3">
                <Row className="justify-content-center">
                    <Col md={6} sm={12} className="text-center text-md-start">
                        <StaticImage src="../../images/logo.png" width={250} />
                        <Row className="mt-4 align-items-center ms-3 justify-content-center justify-content-md-start">
                            <Col xs={'auto'}>
                                <Link href="https://instagram.com/elsanjuanvn" target="_blank">
                                    <StaticImage src="../../images/icon-instagram.png" />
                                </Link>
                            </Col>
                            <Col xs={'auto'}>
                                <Link href="https://api.whatsapp.com/send?phone=5493794653436&text=Hola! Te escribo desde la web.">
                                    <StaticImage src="../../images/icon-whatsapp.png" target="_blank" />
                                </Link>
                            </Col>
                            <Col xs={'auto'}>
                                <Link href="mailto:elsanjuanvn@gmail.com">
                                    <StaticImage src="../../images/icon-message.png" target="_blank" />
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3} sm={6} className=" mt-5 mt-md-4 text-center text-sm-start">
                        <Link to="/" style={{ textDecoration: "none" }}>
                            <P>INICIO</P>
                        </Link>
                        <Link to="/contacto" style={{ textDecoration: "none" }}>
                            <P>CONTACTO</P>
                        </Link>
                        <Link to="/portafolio" style={{ textDecoration: "none" }}>
                            <P>PORTAFOLIO</P>
                        </Link>
                    </Col>
                    <Col md={3} sm={6} className=" mt-5 mt-md-4 text-center text-sm-start">
                        <P>TELEFONOS</P>
                        <Link style={{ textDecoration: "none" }} href="tel:+54 9 3794 653436">
                            <P>+54 9 3794 653436</P>
                        </Link>
                        <P>CORREO</P>
                        <Link style={{ textDecoration: "none" }} href="mailto:elsanjuanvn@gmail.com">
                            <P>ELSANJUANVN@GMAIL.COM</P>
                        </Link>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <P className="text-center py-4 mb-0">©2021 SAN JUAN. Diseñado y desarrollado por <span><Link style={{ textDecoration: "none", color: "#231F20" }} target="_blank" href="https://aurorastudio.tech/">Aurora Studio</Link></span></P>
            </Container>
        </ContainerGeneral>
    );
}

export default Footer;